export default {
  SET_ALLGAMME(state, data) {
    state.gammeAll = data;
  },
  SET_ALLMARCHE(state, data) {
    state.marcheAll = data;
  },
  SET_ALLPRIX(state, data) {
    state.prixAll = data;
  },
  SET_ALLPRIX_MOY(state, data) {
    state.prix_moyAll = data;
  },
  SET_ALLPRODUIT(state, data) {
    state.produitAll = data;
  },
  SET_ALLREGION(state, data) {
    state.regionAll = data;
  },


}