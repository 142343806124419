export default{
    getgamme: (state) => state.gammeAll,
    getmarche: (state) => state.marcheAll,
    getprix: (state) => state.prixAll,
    getproduit: (state) => state.produitAll,
    getregion: (state) => state.regionAll,
    getprix_moy: (state) => state.prix_moyAll



}